import Image from "@components/shared/sub/image";
import { SanityLeadershipPage } from "@graphql-types";
import React from "react";
import styled from "styled-components";
interface Props {
  sanityLeadershipPage: SanityLeadershipPage;
}

const StyledMembershipPage = styled.div`
  #leadership_member .container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 6rem 8rem;
    border-radius: 0.5rem;
    margin-bottom: 40px;
    max-width: 1440px;
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    #leadership_member .container {
      padding: 3rem 0;
      box-shadow: unset;
    }
  }
  #leadership_member .container > h2 {
    text-align: center;
    font-size: 2.5rem;
  }
  #leadership_member .container > p {
    text-align: center;
    max-width: 56rem;
    margin: 1rem auto;
  }
  #leadership_member .container .members {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
    margin-top: 6rem;
  }
  @media screen and (max-width: 1000px) {
    #leadership_member .container .members {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 600px) {
    #leadership_member .container .members {
      grid-template-columns: 1fr;
    }
  }
  #leadership_member .container .members .member {
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    #leadership_member .container .members .member {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 1rem;
      margin-bottom: 1rem;
    }
  }
  #leadership_member .container .members .member .gatsby-image-wrapper {
    height: 14rem !important;
    width: 14rem !important;
    border-radius: 20rem;
    overflow: hidden;
    margin: 0 auto;
    background-color: var(--light-blue);
  }
  @media screen and (max-width: 600px) {
    #leadership_member .container .members .member .gatsby-image-wrapper {
      height: 8rem !important;
      width: 8rem !important;
    }
  }
  #leadership_member .container .members .member .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  #leadership_member .container .members .member .content span {
    display: block;
    font-family: "header", sans-serif;
    width: fit-content;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    #leadership_member .container .members .member .content span {
      text-align: left;
      width: 100%;
    }
  }
  #leadership_member .container .members .member .content span.name {
    margin-bottom: 0.4rem;
    color: "black";
    font-size: 18px;
  }
  #leadership_member .container .members .member .content span.job-title {
    font-family: "header bold", sans-serif;
    line-height: 22px;
    color: #6d7278;
    font-size: 17px;
  }
`;

const Members = (props: Props) => {
  const { sanityLeadershipPage: data } = props;

  if (data == null) return null;

  const { members, advisoryMembers, clinicalMembers } = data;

  return (
    <StyledMembershipPage>
      <div id="leadership_member">
        <div className="container">
          <h2>{data?.members?.title}</h2>
          <p>{data?.members?.description}</p>
          <div className="members">
            {members?.members &&
              members.members.map(member => {
                if (member == null) return null;
                const { _key, profileImage, fullName, jobTitle } = member;

                return (
                  <div key={_key} className="member">
                    {profileImage && <Image data={profileImage} />}
                    <div className="content">
                      <span className="name">{fullName}</span>
                      <span className="job-title">{jobTitle}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {advisoryMembers && (
          <div className="container">
            <h2>{advisoryMembers.title}</h2>
            <p>{advisoryMembers.description}</p>
            <div className="members">
              {advisoryMembers.members &&
                advisoryMembers.members.map(member => {
                  if (member == null) return null;

                  const { _key, profileImage, fullName, jobTitle } = member;
                  return (
                    <div key={_key} className="member">
                      {profileImage && (
                        <Image data={profileImage} width="100%" />
                      )}
                      <div className="content">
                        <span className="name">{fullName}</span>
                        <span className="job-title">{jobTitle}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {clinicalMembers && (
          <div className="container">
            <h2>{clinicalMembers.title}</h2>
            <p>{clinicalMembers.description}</p>
            <div className="members">
              {clinicalMembers.members &&
                clinicalMembers.members.map(member => {
                  if (member == null) return null;
                  const { _key, profileImage, fullName, jobTitle } = member;
                  return (
                    <div key={_key} className="member">
                      {profileImage && (
                        <Image data={profileImage} width="100%" />
                      )}
                      <div className="content">
                        <span className="name">{fullName}</span>
                        <span className="job-title">{jobTitle}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </StyledMembershipPage>
  );
};

export default Members;
