import React from "react";

export interface VideoProps {
  className?: string;
  src: string;
  width: string;
  height: string;
}

export const Video = ({ src, className, width, height }: VideoProps) => {
  return (
    <video
      src={src}
      className={className}
      autoPlay
      loop
      muted
      playsInline
      width={width}
      height={height}
    />
  );
};
