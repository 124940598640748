import { GridContainer, MenuButtonGatsbyLink } from "@util/standard";
import React, { useEffect, useState } from "react";
import BlocksContent from "./sub/blockContent";

import {
  BlockContentContainer,
  ButtonsWrapper,
  DownloadButton,
  DownloadText,
  GridWrapperContainer,
  HeroTextContainer,
  ImageWrapper,
  StyledGif,
  StyledImg,
  StyledVideo,
} from "./hero.styles";

import { Maybe, SanityHero } from "@graphql-types";
import { useStore } from "@state/store";
import { isNotNullOrUndefined } from "@util/assertions";
import { MAX_WIDTH } from "@util/constants";

interface Props {
  heroContent?: Maybe<Maybe<SanityHero>[]>;
  singleHeroContent?: Maybe<SanityHero>;
  featuredHero?: boolean;
}

function Hero(props: Props) {
  const { heroContent, featuredHero, singleHeroContent } = props;

  let heroContentSort: SanityHero[] = [];

  if (singleHeroContent) {
    heroContentSort = [singleHeroContent];
  } else if (heroContent) {
    heroContentSort = heroContent.filter(isNotNullOrUndefined);
  }

  const [locationHero, setLocationHero] = useState(heroContentSort[0]);

  const { ipLookup, region } = useStore();

  useEffect(() => {
    if (heroContentSort.length > 1) {
      if (region.iban != "gl") {
        const filteredHeroData = heroContentSort.filter(
          hero =>
            hero.locationsArray &&
            hero.locationsArray
              .map(location => location?.iban)
              .includes(region.iban)
        );

        if (filteredHeroData.length > 0) {
          setLocationHero(filteredHeroData[0]);
        } else {
          setLocationHero(heroContentSort[0]);
        }
      } else {
        setLocationHero(heroContentSort[0]);
      }
    } else {
      setLocationHero(heroContentSort[0]);
    }
  }, [region]);

  const {
    heroText,
    downloadLinkText,
    downloadLinks,
    heroLink,
    heroImage,
    heroVideo,
  } = locationHero;

  return (
    <GridWrapperContainer
      gridTemplateColumns="repeat(2,1fr)"
      margin="20px auto 50px auto"
      tabletMargin="0px auto 0px auto"
      tabletWidth="75%"
      maxWidth={MAX_WIDTH}
      featuredHero={featuredHero}
    >
      <HeroTextContainer>
        <BlockContentContainer featuredHero={featuredHero}>
          <BlocksContent blocks={heroText?._rawContent} />
        </BlockContentContainer>
        {downloadLinkText && downloadLinks && (
          <ButtonsWrapper>
            {(ipLookup.isIncludedCountry || ipLookup.failedFetch) && (
              <>
                {heroLink && (
                  <MenuButtonGatsbyLink
                    href={heroLink.url ?? ""}
                    target="_blank"
                    width="fit-content"
                    mobileWidth="100%"
                    tabletWidth="100%"
                    margin="20px 0px 45px 0px"
                    padding="8.5px 20px"
                    color="secondary"
                    isInternalLink={heroLink.internallink}
                  >
                    {heroLink.linktext}
                  </MenuButtonGatsbyLink>
                )}
                {downloadLinkText && (
                  <DownloadText desktop>{downloadLinkText}</DownloadText>
                )}
                {downloadLinks && (
                  <>
                    <GridContainer
                      gridTemplateColumns="repeat(2,1fr)"
                      width="fit-content"
                      tabletRepeat={2}
                      mobileRepeat={2}
                      tabletMargin="20px auto"
                      gridGap="10px"
                      tabletWidth="fit-content"
                    >
                      {downloadLinks.map(link => {
                        return (
                          <a
                            href={link?.linkTo ?? ""}
                            target="_blank"
                            key={link?._key}
                          >
                            <DownloadButton
                              src={
                                link?.socialIcon?.asset?.gatsbyImageData.images
                                  .fallback.src ?? ""
                              }
                              alt={"Download button"}
                              width="100%"
                              height="100%"
                            />
                          </a>
                        );
                      })}
                    </GridContainer>
                    <DownloadText>{downloadLinkText}</DownloadText>
                  </>
                )}
              </>
            )}
          </ButtonsWrapper>
        )}
      </HeroTextContainer>
      <ImageWrapper
        noDownloadLinks={!heroLink}
        featuredHero={featuredHero}
        aspectRatio={heroVideo?.endsWith(".mp4") ? "1 / 1" : "auto"}
      >
        {heroVideo?.endsWith(".gif") ? (
          <StyledGif
            src={heroVideo}
            alt={"Video Thumb"}
            width="100%"
            height="100%"
          />
        ) : heroVideo?.endsWith(".mp4") ? (
          <StyledVideo src={heroVideo} width="500" height="500" />
        ) : (
          <StyledImg
            data={heroImage ?? null}
            width="100%"
            height="inherit"
            loading="eager"
          />
        )}
      </ImageWrapper>
    </GridWrapperContainer>
  );
}

export default Hero;
